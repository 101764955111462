<template>
  <div class="paymentIssues">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">支付问题</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <p>1.因超时支付无法到账，请重新发起。</p>

      <p>2.每天发起支付不能超过5次，连续发起且未支付，账号可能被加入黑名单。</p>

      <p>
        3.支付通道在夜间比较忙碌，尝试多次发起，后台会为您自动切换不同支付通道。
      </p>

      <p>4.若充值成功，用户权益通常会在数分钟内到账，请刷新APP或重启。</p>

      <p>
        6.若支付成功并重启APP后依然没有到账，请联系在线客服并提供付款成功凭证截图。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.paymentIssues {
  height: 100%;
  // background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-bottom: 0.5px solid #f3f3f3;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      // flex: 2;
      // text-align: center;
      font-size: 16px;
      color: #000;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    padding: 12px 16px 0;
    box-sizing: border-box;
    font-size: 14px;
    color: #333;
    line-height: 30px;
    p {
      margin-bottom: 30px;
    }
  }
}
</style>
